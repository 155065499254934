<template>
    <div class="surface-0 overflow-hidden">
        
        <div class="grid py-4 px-4 lg:px-8 relative" id="inicio">
            <div class="mx-4 md:mx-8 mt-0 md:mt-4">
                <h1 class="text-6xl font-bold text-gray-900 line-height-2">
                    <span class="font-light block">
                    </span>Petro Servicios
                </h1>
                <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">
                    Somos una empresa nacional de capital 100% venezolano, fundada en el año 2017 por empresarios privados comprometidos con el desarrollo económico del país. 

                </p>
            </div>
            <img src="images/imagen.png" class="bottom-0" alt="inicio screen" style="right:10%;">
        </div>
        
        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="introduccion">

            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-4 bg-purple-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="images/imagen1.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-8 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <div class="flex align-items-center justify-content-center bg-purple-200" style="width:4.2rem; height:4.2rem; border-radius: 10px;">
                        <i class="pi pi-fw pi-globe text-5xl text-purple-700"></i>
                    </div>
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Nuestra Empresa</h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">
                        Especializados y dispuestos a ofrecer efectivas soluciones técnicas a la  industria petrolera nacional en el suministro de equipos 
                        y accesorios para la completación de sistemas de levantamiento artificial, con el mejor servicio de mantenimiento, restauración y 
                        reparación de estos equipos, con el fin de preservar y prolongar su vida útil; satisfaciendo así las exigencias de nuestros clientes, 
                        generando fuentes de empleos tanto directo como indirecto, apoyando la calidad de nuestra mano de obra y talento tecnológico nacional  
                        e impactando positivamente en la coyuntura económica nacional a través del ahorro de divisas y el incremento de la producción petrolera.
                        Cabe destacar que contamos con un personal altamente capacitado y experimentado con amplios conocimientos de  técnicos  enfocado en proporcionar 
                        el mejor servicio y la mejor atención a nuestra industria nacional.
                    </span>
                </div>
            </div>

            <div class="grid my-8 pt-2 md:pt-8">
                <div class="col-12 lg:col-8 my-auto flex flex-column lg:align-items-start align-items-center">
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Misión</h2>
                    <span class="text-700 text-2xl line-height-3 mr-0 md:mr-2" style="max-width:650px;">
                        Ofrecer un servicio de calidad para así aportar las más avanzadas herramientas con la finalidad de lograr 
                        la satisfacción completa de nuestros clientes; siempre de la mano con nuestro capacitado talento humano, 
                        el cual nos hace alcanzar la excelencia en el servicio obteniendo con esto soluciones efectivas.
                    </span>
                </div>
                <div class="flex justify-content-center col-12 lg:col-4 bg-yellow-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="images/imagen2.png" class="w-11" alt="mockup mobile">
                </div>
            </div>
            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-4 bg-purple-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="images/imagen3.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-8 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Visión</h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">
                        Posicionarnos como empresa líder en servicios para la industria petrolera a nivel nacional por su calidad de servicio.
                    </span>
                </div>
            </div>


        </div>




        <!--






        <div class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8" id="features">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-8 mb-4">
                    <h2 class="text-900 font-normal mb-2">Marvelous Features</h2>
                    <span class="text-600 text-2xl">Placerat in egestas erat...</span>
                </div>

                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(187, 199, 205, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-users text-2xl text-yellow-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Easy to Use</h5>
                            <span class="text-600">Posuere morbi leo urna molestie.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-palette text-2xl text-cyan-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Fresh Design</h5>
                            <span class="text-600">Semper risus in hendrerit.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145, 226, 237, 0.2), rgba(172, 180, 223, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(246, 158, 188, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-indigo-200" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-map text-2xl text-indigo-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Well Documented</h5>
                            <span class="text-600">Non arcu risus quis varius quam quisque.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(251, 199, 145, 0.2)), linear-gradient(180deg, rgba(253, 228, 165, 0.2),rgba(145, 210, 204, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-bluegray-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-id-card text-2xl text-bluegray-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Responsive Layout</h5>
                            <span class="text-600">Nulla malesuada pellentesque elit.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(187, 199, 205, 0.2),rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(145, 226, 237, 0.2),rgba(160, 210, 250, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-orange-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-star text-2xl text-orange-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Clean Code</h5>
                            <span class="text-600">Condimentum lacinia quis vel eros.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(251, 199, 145, 0.2), rgba(246, 158, 188, 0.2)), linear-gradient(180deg, rgba(172, 180, 223, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-pink-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-moon text-2xl text-pink-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Dark Mode</h5>
                            <span class="text-600">Convallis tellus id interdum velit laoreet.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(160, 210, 250, 0.2)), linear-gradient(180deg, rgba(187, 199, 205, 0.2), rgba(145, 210, 204, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-teal-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-shopping-cart text-2xl text-teal-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Ready to Use</h5>
                            <span class="text-600">Mauris sit amet massa vitae.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg:pr-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(145, 210, 204, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(251, 199, 145, 0.2), rgba(160, 210, 250, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-blue-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-globe text-2xl text-blue-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Modern Practices</h5>
                            <span class="text-600">Elementum nibh tellus molestie nunc non.</span>
                        </div>
                    </div>
                </div>
        
                <div class="col-12 md:col-12 lg:col-4 p-0 lg-4 mt-4 lg:mt-0">
                    <div style="height:160px; padding:2px; border-radius:10px; background: linear-gradient(90deg, rgba(160, 210, 250, 0.2), rgba(212, 162, 221, 0.2)), linear-gradient(180deg, rgba(246, 158, 188, 0.2), rgba(212, 162, 221, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-purple-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-eye text-2xl text-purple-700"></i>
                            </div>
                            <h5 class="mb-2 text-900">Privacy</h5>
                            <span class="text-600">Neque egestas congue quisque.</span>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-8 mb-8 p-2 md:p-8" style="border-radius:20px; background:linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EFE1AF 0%, #C3DCFA 100%);">
                    <div class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0">
                        <h3 class="text-gray-900 mb-2">Joséphine Miller</h3>
                        <span class="text-gray-600 text-2xl">Peak Interactive</span>
                        <p class="text-gray-900 sm:line-height-2 md:line-height-4 text-2xl mt-4" style="max-width:800px;">“Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.”</p>
                        <img src="layout/images/peak-logo.svg" class="mt-4" alt="">

                    </div>
                </div>
            </div>
        </div>
            
        <div class="py-4 px-4 lg:px-8 mx-0 my-6 lg:mx-8" id="highlight">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Powerful Everywhere</h2>
                <span class="text-600 text-2xl">Amet consectetur adipiscing elit...</span>
            </div>

            <div class="grid mt-8 pb-2 md:pb-8">
                <div class="flex justify-content-center col-12 lg:col-6 bg-purple-100 p-0 flex-order-1 lg:flex-order-0" style="border-radius:8px;">
                    <img src="layout/images/mockup.png" class="w-11" alt="mockup mobile">
                </div>

                <div class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end lg:text-right align-items-center text-center ">
                    <div class="flex align-items-center justify-content-center bg-purple-200" style="width:4.2rem; height:4.2rem; border-radius: 10px;">
                        <i class="pi pi-fw pi-mobile text-5xl text-purple-700"></i>
                    </div>
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Congue Quisque Egestas</h2>
                    <span class="text-700 text-2xl line-height-3 ml-0 md:ml-2" style="max-width:650px;">Lectus arcu bibendum at varius vel pharetra vel turpis nunc. Eget aliquet nibh praesent tristique magna sit amet purus gravida. Sit amet mattis vulputate enim nulla aliquet.</span>
                </div>
            </div>

            <div class="grid my-8 pt-2 md:pt-8">
                <div class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-start align-items-center">
                    <div class="flex align-items-center justify-content-center bg-yellow-200" style="width:4.2rem; height:4.2rem; border-radius:10px;">
                        <i class="pi pi-fw pi-desktop text-5xl text-yellow-700"></i>
                    </div>
                    <h2 class="line-height-1 text-900 text-4xl font-normal">Celerisque Eu Ultrices</h2>
                    <span class="text-700 text-2xl line-height-3 mr-0 md:mr-2" style="max-width:650px;">Adipiscing commodo elit at imperdiet dui. Viverra nibh cras pulvinar mattis nunc sed blandit libero. Suspendisse in est ante in. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi.</span>
                </div>

                <div class="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 bg-yellow-100 p-0" style="border-radius:8px;">
                    <img src="layout/images/mockup-desktop.png" class="w-11 pt-4" alt="mockup">
                </div>
            </div>
        </div>

        <div class="py-4 px-4 lg:px-8 my-2 md:my-4" id="pricing">
            <div class="text-center">
                <h2 class="text-900 font-normal mb-2">Matchless Pricing</h2>
                <span class="text-600 text-2xl">Amet consectetur adipiscing elit...</span>
            </div>

            <div class="grid justify-content-between mt-8 md:mt-0">
                <div class="col-12 lg:col-4 p-0 md:p-3">
                    <div class="p-3 flex flex-column border-200 hover:border-cyan-200" style="border:2px solid; border-radius:10px;">
                        <h3 class="text-900 text-center">Free</h3>
                        <img src="layout/images/asset-tier-1.svg" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <span class="text-5xl font-bold mr-2 text-900">$0</span>
                            <span class="text-600">per month</span>
                            <Button label="Get Started" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2 bg-blue-500"></button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Responsive Layout</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Unlimited Push Messages</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">50 Support Ticket</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Free Shipping</span>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-12 lg:col-4 p-0 md:p-3 mt-4 md:mt-0">
                    <div class="p-3 flex flex-column border-200 hover:border-cyan-200" style="border:2px solid; border-radius:10px;">
                        <h3 class="text-900 text-center">Startup</h3>
                        <img src="layout/images/asset-tier-2.svg" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <span class="text-5xl font-bold mr-2 text-900">$1</span>
                            <span class="text-600">per month</span>
                            <Button label="Try Free" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2 bg-blue-500"></Button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Responsive Layout</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Unlimited Push Messages</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">50 Support Ticket</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Free Shipping</span>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-12 lg:col-4 p-0 md:p-3 mt-4 md:mt-0">
                    <div class="p-3 flex flex-column border-200 hover:border-cyan-200" style="border:2px solid; border-radius:10px;">
                        <h3 class="text-900 text-center">Enterprice</h3>
                        <img src="layout/images/asset-tier-3.svg" class="w-10 h-10 mx-auto" alt="">
                        <div class="my-5 text-center">
                            <span class="text-5xl font-bold mr-2 text-900">$999</span>
                            <span class="text-600">per month</span>
                            <Button label="Get a Quote" class="block mx-auto mt-4 p-button-rounded border-none ml-3 font-light text-white line-height-2 bg-blue-500"></Button>
                        </div>
                        <Divider class="w-full bg-surface-200"></Divider>
                        <ul class="my-5 list-none p-0 flex text-900 flex-column">
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Responsive Layout</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Unlimited Push Messages</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">50 Support Ticket</span>
                            </li>
                            <li class="py-2">
                                <i class="pi pi-fw pi-check text-xl text-cyan-500 mr-2"></i>
                                <span class="text-xl line-height-3">Free Shipping</span>
                            </li>
                        </ul>
                    </div>
                </div>
        </div> 
        </div>
        -->
        <div class="py-4 px-4 mx-0 mt-8 lg:mx-8" id="ubicacion">
            <Ubicacion/>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from "@vue/runtime-core";

export default {
	props: {
		navegar: {
			type: String,
			default: null
		}
	},
	components: {
		Ubicacion: defineAsyncComponent(() => import('../components/Ubicacion.vue')),
	},
	data() {
		return {
			d_navegar: this.navegar,
            temporal: null,
		}
	},
    watch: {
		navegar(newValue) {
			this.smoothScroll(newValue);
		}
	},
    methods: {
        smoothScroll(id){
            const ini = document.querySelector('#inicio');
			const el = document.querySelector('#'+id);
			var y = el.getBoundingClientRect().top - 70;           
            const med = ini.getBoundingClientRect().top - 70;
            //if(this.temporal != 'inicio' && id != 'inicio'){
                y = y - med;
            //}
			window.scrollTo({top: y, behavior: 'smooth'});
            this.temporal = id;         
        },
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style>
#inicio{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #5A5A5A 0%, #CBCBCB 100%);
    height:700px;
    overflow:hidden;
}

@media screen and (min-width: 768px) {
    #inicio{
        -webkit-clip-path: ellipse(150% 87% at 93% 13%);
        clip-path: ellipse(150% 87% at 93% 13%);
        height: 530px;
    }
}

@media screen and (min-width: 1300px){
    #inicio > img {
        position: absolute;
    }

    #inicio > div > p { 
        max-width: 450px;
    }
}

@media screen and (max-width: 1300px){
    #inicio {
        height: 600px;
    }

    #inicio > img {
        position:static;
        transform: scale(1);
        margin-left: auto;
    }

    #inicio > div {
        width: 100%;
    }

    #inicio > div > p {
        width: 100%;
        max-width: 100%;
    }
}
</style>